import React, { useEffect, useState } from "react";
import "./App.css";
import azkarData from "./data/azkar-data.json";
import hadithData from "./data/hadith-data.json";
import ZikrCard from "./components/ZikrCard";

function App() {
    const [azkar, setAzkar] = useState<{}[]>([]);
    const [active, setActive] = useState("morning");
    const [dismissNotice, setDismissNotice] = useState(false);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.get("mode") === "private") {
            setActive("private");
            setAzkar(hadithData);
        } else {
            setAzkar(azkarData?.morning);
        }
    }, []);

    const azkarMarkup = azkar.map((zikr: any, index) => (
        <ZikrCard
            data={zikr}
            index={index}
            key={active + "-" + index + 1}
            active={active}
        />
    ));

    const bgColor = active === "private" ? "bg-emerald-100" : "bg-purple-100";
    const textColor =
        active === "private" ? "text-emerald-400" : "text-purple-400";
    const borderColor =
        active === "private" ? "border-emerald-400" : "border-purple-400";

    return (
        <div
            className={`max-h-screen shadow-xl  flex flex-col items-center ${bgColor}`}
        >
            {!navigator.onLine && (
                <h1
                    className={`text-lg text-center w-full font-extrabold bg-slate-500 ${textColor}`}
                >
                    You are currently offline.
                </h1>
            )}

            {!dismissNotice && !localStorage.localStorageNotice && (
                <div className="flex flex-row justify-around w-full p-2 rounded-lg bg-white m-2">
                    <h2 className="text-lg text-center w-full font-extrabold bg-white">
                        يمكن للتطبيق الاحتفاظ بادخالاتك على جهازك محليا
                    </h2>
                    <br />
                    <button
                        className={`rounded-lg px-4 mx-2 w-auto ${borderColor} border-4`}
                        onClick={() => {
                            setDismissNotice(true);
                        }}
                    >
                        اخفاء
                    </button>
                    <button
                        className={`rounded-lg px-4 w-auto ${borderColor} border-4`}
                        onClick={() => {
                            setDismissNotice(true);
                            localStorage.setItem("localStorageNotice", "true");
                        }}
                    >
                        موافق
                    </button>
                </div>
            )}

            <div className="flex flex-row justify-around max-w-xl p-2 m-2 rounded-lg bg-white">
                <h1 className=" text-3xl font-bold underline pr-10">azkar.</h1>
                <button
                    className={`rounded-lg px-4 ${
                        active === "evening" && "border-purple-400 border-4"
                    }`}
                    onClick={() => {
                        setAzkar(azkarData.evening);
                        setActive("evening");
                    }}
                >
                    مساء
                </button>

                <button
                    className={`rounded-lg px-4 ${
                        active === "morning" && "border-purple-400 border-4"
                    }`}
                    onClick={() => {
                        setAzkar(azkarData.morning);
                        setActive("morning");
                    }}
                >
                    صباح
                </button>
                <button
                    className={`rounded-lg px-4 ${
                        active === "private" && "border-emerald-400 border-4"
                    }`}
                    onClick={() => {
                        setAzkar(hadithData);
                        setActive("private");
                    }}
                >
                    خاص
                </button>
            </div>
            <div className="flex-1 overflow-scroll p-4 gap-4 max-w-xl bg-white rounded-tr-lg rounded-tl-lg">
                {azkarMarkup}
            </div>
        </div>
    );
}

export default App;
