import React, { useEffect, useState } from "react";

export interface ZikrCardProps extends React.HTMLAttributes<HTMLDivElement> {
    data?: {
        preZikr: string;
        zikrMushakal: string;
        zikr: string;
        postZikr: string;
        ajr: string;
        count: number;
        sources: Array<string>;
        quraan: boolean;
        content: string;
        source: string;
    };
    active: string;
    index?: number;
    key?: string;
}

const ZikrCard: React.FC<ZikrCardProps> = ({
    data = { count: 0 },
    active,
    index = 0,
    ...ZikrCardProps
}) => {
    const [count, setCount] = useState(0);
    const [completed, setCompleted] = useState(false);

    const isVibrationSupported = navigator.vibrate !== undefined;

    function vibrateOnTap() {
        if (isVibrationSupported) {
            navigator.vibrate(200); // Adjust duration (ms) as needed
        } else {
            console.warn("Vibration API not supported by your browser.");
        }
    }

    // Save the state of a card locally to help the user maintain consistency and not lose progress if the app refreshes
    // use a combination of the date, the active tab, and the index of the card to create a unique key for each card in local storage
    const today = new Date().toJSON().slice(0, 10);

    // save the state of the card in lolcal storage to keep the count after refresh for a given date
    useEffect(() => {
        const savedState = localStorage.getItem(today + active + index);
        if (savedState) {
            setCount(JSON.parse(savedState));
        }
    }, [index, active, today]);

    useEffect(() => {
        if (count !== 0 && localStorage.localStorageNotice) {
            // to avoid saving the initial state of 0
            localStorage.setItem(today + active + index, count + "");
        }
    }, [count, index, active, today]);

    useEffect(() => {
        if (count >= data.count) {
            setCompleted(true);
        }
    }, [count, data.count]);

    // card interaction functions
    const increment = () => {
        vibrateOnTap();
        setCount((prev) => prev + 1);
    };

    const reset = () => {
        setCount(0);
        if (localStorage.localStorageNotice) {
            localStorage.setItem(today + active + index, "0"); // exception for the reset button
        }
        setCompleted(false);
    };

    const complete = () => {
        setCount(data.count);
        setCompleted(true);
    };

    const bgColor = active === "private" ? "bg-green-200" : "bg-purple-200";
    const shadowColor =
        active === "private" ? "shadow-green-500" : "shadow-purple-500";
        const bgCompleteColor =
            active === "private" ? "bg-emerald-400" : "bg-indigo-400";
            const secondaryTextColor =
                active === "private" ? "text-[#aaD700]" : "text-[#aaD700]";

    let cardMarkup = data ? (
        <div
            {...ZikrCardProps}
            dir="rtl"
            className={`p-2 my-4 rounded-3xl  ${shadowColor} bg-opacity-25 outline ${
                !completed && count > 0 && "bg-amber-300 shadow-md outline-none"
            } ${
                completed && `${bgCompleteColor} outline-none`
            } flex flex-col justify-center text-center`}
        >
            <div
                className={`flex flex-row-reverse justify-between ${bgColor} rounded-3xl text-gray-400`}
            >
                <button className="px-4 underline font-bold" onClick={reset}>
                    إعادة
                </button>

                <button className="px-4 underline font-bold" onClick={complete}>
                    إنهاء
                </button>

                <p>{convertToArabicNumerals(index + 1)}</p>

                <p className="pr-2">
                    تكرار: {convertToArabicNumerals(count)}/
                    {convertToArabicNumerals(data.count)}
                </p>
            </div>
            <div onClick={increment}>
                <p className={`${secondaryTextColor}`}>{data.preZikr}</p>
                <p className="text-lg">
                    {data?.zikrMushakal ? data.zikrMushakal : data.zikr}
                </p>
                <p className="text-lg">{data?.content}</p>
                {/* <p>{data.zikr}</p> */}
                <p className={`${secondaryTextColor}`}>
                    {data.postZikr}
                    {data.source}
                </p>
                <p className="text-fuchsia-500">{data.ajr}</p>
            </div>
        </div>
    ) : (
        <div>'data missing'</div>
    );

    function convertToArabicNumerals(number: number): string {
        const arabicNumerals = [
            "٠",
            "١",
            "٢",
            "٣",
            "٤",
            "٥",
            "٦",
            "٧",
            "٨",
            "٩",
        ];
        return number
            .toString()
            .split("")
            .map((digit) => arabicNumerals[parseInt(digit)])
            .join("");
    }
    return cardMarkup;
};

export default ZikrCard;
